import React from "react"
import tw from "twin.macro"
import IconInertia from "@images/ih/shared/sleeping-person-icon.svg"
import IconCognitive from "@images/ih/shared/brain-icon.svg"
import IconTime from "@images/ih/shared/clock-icon.svg"
import IconSleeping from "@images/ih/shared/bed-icon.svg"
import IconEye from "@images/ih/shared/eye-icon.svg"
import BoxedIconText from "@components/BoxedIconText"

const AllSymptomsIh = () => {
  const symptoms = [
    {
      icon: IconInertia,
      text: (
        <>
          <p>Sleep inertia</p>
          <p tw="text-[0.75rem] font-normal mt-1">
            Severe grogginess or confusion when waking up regardless of how much
            sleep you get or how many alarms you set&mdash;feeling disoriented,
            irritable, or even clumsy when waking up and for hours afterwards
          </p>
        </>
      ),
    },
    {
      icon: IconCognitive,
      text: (
        <>
          <p>Cognitive impairment</p>
          <p tw="text-[0.75rem] font-normal mt-1">
            Difficulty focusing, trouble thinking clearly, or memory problems
            due to excessive daytime sleepiness&mdash;often described as “brain
            fog”
          </p>
        </>
      ),
    },
    {
      icon: IconTime,
      text: (
        <>
          <p>Long sleep time</p>
          <p tw="text-[0.75rem] font-normal mt-1">
            <>
              Ability to sleep for many hours of the day&mdash;
              <br tw="hidden lg:block" />
              sometimes for 11 hours or more
            </>
          </p>
        </>
      ),
    },
    {
      icon: IconSleeping,
      text: (
        <>
          <p>Non-restorative sleep</p>
          <p tw="text-[0.75rem] font-normal mt-1">
            Not feeling rested when you wake up, despite sleeping a typical (or
            more than typical) amount each night
          </p>
        </>
      ),
    },
    {
      icon: IconEye,
      text: (
        <>
          <p>Long, unrefreshing naps</p>
          <p tw="text-[0.75rem] font-normal mt-1">
            Not feeling refreshed or better after napping (may even feel worse)
          </p>
        </>
      ),
    },
  ]
  return (
    <div tw="lg:(grid grid-cols-2 gap-[1.875rem])">
      {symptoms.map((symptom, index) => {
        return (
          <BoxedIconText
            key={index}
            css={[
              tw`col-span-2 mb-4 lg:(col-span-1 mb-0 -my-3)`,
              index % 2 === 0 ? tw`lg:(col-start-1)` : tw`lg:(col-start-2)`,
            ]}
            icon={symptom.icon}
            text={symptom.text}
          />
        )
      })}
    </div>
  )
}

export default AllSymptomsIh
