import React from "react"
import tw from "twin.macro"
import StaticHeroIh from "@components/staticHeroIh"
import Section from "@components/section"
import Container from "@components/container"
import SecondaryCallout from "@components/SecondaryCallout"
import StayConnectedBannerIh from "@components/StayConnectedBannerIh"
import Flashcards from "@components/Flashcards"
import { smallPurpleHeadingIh, headingIh, sectionIh } from "@styles/ui"

// Components
import Layout from "@components/layout"
import Seo from "@components/seo"

import AllSymptomsIh from "../../components/AllSymptomsIh"

const flashcardData = [
  {
    question: "Excessive daytime sleepiness is the only symptom of IH.",
    answers: [{ answer: "true" }, { answer: "false", isCorrect: true }],
    response:
      "IH is a 24-hour condition with multiple symptoms including excessive daytime sleepiness, sleep inertia (severe grogginess or confusion when waking up), cognitive impairment (brain fog), long sleep time, and unrefreshing sleep.",
  },
  {
    question:
      "Feeling extremely groggy while trying to wake up after sleep is no different than daytime sleepiness.",
    answers: [{ answer: "true" }, { answer: "false", isCorrect: true }],
    response:
      "The severe and lasting grogginess and confusion you feel when you try to wake up could be sleep inertia, a symptom of IH that can make it feel impossible to get out of bed.",
  },
  {
    question:
      "If you have IH, getting more sleep will make you feel fully rested and energized throughout the day.",
    answers: [{ answer: "true" }, { answer: "false", isCorrect: true }],
    response:
      "With IH, no matter how much you sleep, it’s often not refreshing. Similar to sleep at night, daytime naps may also be long (often >1 hour) and are unrefreshing as well.",
  },
  {
    question: "There is no FDA-approved medication to treat IH.",
    answers: [{ answer: "true" }, { answer: "false", isCorrect: true }],
    response:
      "XYWAV is the first and only FDA-approved medication to treat IH in adults. XYWAV has been studied across multiple symptoms of IH, such as excessive daytime sleepiness, sleep inertia, cognitive impairment, and long sleep time.",
  },
]

const TreatingIH = ({ location }) => {
  return (
    <Layout location={location}>
      <Seo
        title="Idiopathic Hypersomnia Symptoms and Treatment | XYWAV® IH"
        description="Understand the symptoms of Idiopathic Hypersomnia (IH). Complete the IH quiz to help you better understand your symptoms."
        location={location}
      />
      <StaticHeroIh
        variant="asleep"
        heading="Managing IH"
        subheading={
          <span tw="block">Are you managing the multiple symptoms of IH?</span>
        }
      />
      <Section
        bgColour={tw`bg-lilac`}
        id=""
        addedStyles={tw`pt-0 lg:(-mt-8 pt-10)`}
      >
        <Container isIh>
          <h2 css={[headingIh]}>
            Excessive daytime sleepiness: not the only symptom of IH.
          </h2>
          <p tw="text-studio text-base font-light mb-4 lg:mb-8">
            Other symptoms include:
          </p>
          <AllSymptomsIh />
        </Container>
      </Section>

      <Section addedStyles={sectionIh}>
        <Container isIh>
          <div tw="mb-12 lg:(mb-16 flex justify-between gap-[3.5rem])">
            <div tw="mb-8 lg:(w-1/2 mb-0)">
              <h2 css={[headingIh, tw`mb-4 lg:(mb-6 max-w-[30rem])`]}>
                Are IH symptoms impacting you more than you realize?
              </h2>
              <p tw="text-lg font-normal lg:text-2xl">
                Some of the things you&apos;ve been experiencing may be symptoms
                of IH. Explore the statements below to see if you&apos;d like to
                take the next step to manage your&nbsp;IH.
              </p>
            </div>
            <div tw="lg:(w-1/2)">
              <Flashcards flashcardData={flashcardData} />
            </div>
          </div>

          <SecondaryCallout
            copy={
              <>
                <span css={[smallPurpleHeadingIh, tw`block lg:(mb-4)`]}>
                  Take this quiz to evaluate your IH experience.
                </span>
                <span tw="block max-w-[30rem]">
                  Use the results to take the next step—talking with your doctor
                  or finding a doctor to treat your IH.
                </span>
              </>
            }
            cta="Take The Quiz"
            url="/idiopathic-hypersomnia/your-ih-experience/"
            ctaWidth={tw`min-w-[10.625rem] lg:(min-w-[10.688rem])`}
          />
        </Container>
      </Section>

      <Section bgColour={tw`bg-lilac`} id="" addedStyles={[sectionIh]}>
        <Container isIh>
          <SecondaryCallout
            copy={
              <>
                <span
                  css={[
                    smallPurpleHeadingIh,
                    tw`block lg:(mb-0 max-w-[679px])`,
                  ]}
                >
                  If you have trouble getting up in the morning, problems
                  focusing, and never feel rested no matter how much sleep you
                  get, it’s time to talk to your doctor.
                </span>
              </>
            }
            cta="Find Your Provider"
            url="/idiopathic-hypersomnia/find-a-doctor/"
          />
        </Container>
      </Section>

      <StayConnectedBannerIh />
    </Layout>
  )
}

export default TreatingIH
